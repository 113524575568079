import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreConfigService } from '@sharemactechhub/frontend-library/config-loader';
import { IMapConfig, IMarker, MapComponent, MapModule, MarkerType } from '@sharemactechhub/frontend-library/map';
import { CustomDatePipeModule } from '../../../../shared/pipes/custom-date.pipe';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        CustomDatePipeModule,
        MapModule,
        TranslateModule,
    ],
    selector: 'sam-equipment-location',
    templateUrl: './equipment-location.component.html',
    styleUrls: ['./equipment-location.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentLocationComponent implements OnInit {
    equipment = input<any>();
    private readonly coreConfig = inject(CoreConfigService);
    private readonly cdr = inject(ChangeDetectorRef);

    private readonly googleMapsBaseUrl: string = 'https://www.google.com/maps';
    public equipments: IMarker[] = []
    public config: IMapConfig = {
        apiKey: this.coreConfig.select((r) => r.google.map.apiKey),
        height: '240px',
        width: '100%',
        zoom: 10,
        tilte: 45,
        streetViewControl: false,
        rotateControl: false,
        fullScreenUI: false,
        zoomUI: true,
        scaleControl: true,
        mapTypeControl: false,
        settings: false,
        options: {
            gestureHandling: 'greedy',
        },
        mapTypeControlOptions: {
            position: 6.0,
            mapTypeIds: 'terrain',
        },
        autoComplete: false,
        autoCompleteUI: false,
        autoCompleteOptions: {
            autoCompleteMarker: true,
            autoCompleteZoom: true,
        },
        customControls: false,
        customControlsOptions: {
            polygonControl: false,
            markerControl: false,
            eraseControl: false,
            removeAll: false,
            dragControl: false,
            setMainControl: false,
        },
        polygonOptions: {
            dragable: false,
            editable: false,
            withMarker: false,
        },
        markerOptions: {
            withInfoWindow: false,
            withFormWindow: true,
            withEquipmentInfo: false,
            draggable: false,
            clickable: false,
        },
        withCluster: false,
    };
    @ViewChild(MapComponent) private readonly map!: MapComponent;
    ngAfterViewInit(): void {
        setTimeout(() => {
        this.map.fitMarkerBounds(this.equipments);
            
        }, 100);
    }
    ngOnInit() {
        const result: IMarker = {
            id: this.equipment().id,
            type: MarkerType.EQUIPMENT,
            coordinates: {
                lat: this.equipment()?.position?.lastLatitude,
                lng: this.equipment()?.position?.lastLongitude,
            },
            options: { zIndex: 3, clickable: false },
        };

        if (this.equipment()?.position?.lastLatitude && this.equipment()?.position?.lastLongitude) {
            this.equipments = [result];
            this.cdr.detectChanges()
        }
    }

    public openMapPin(latitude: number, longitude: number): void {
        if (!latitude || !longitude) return
        window.open(
            `${this.googleMapsBaseUrl}/search/?api=1&query=${latitude},${longitude}`,
            '_blank'
        );
    }

}
