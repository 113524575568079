import { CookieService } from 'ngx-cookie-service';
import { getDomain } from './shared/utils/get-domaun.util';

export class StorageMigration {
    constructor(private readonly cookieService: CookieService) {}

    localStorageDataMigration(): void {
        throw new Error('Method not implemented.');
    }

    cookieDataMigration(): void {
        const oldDomainNames = [
            getDomain(window.location.hostname, 2),
            '.dev.syniotec.com',
        ];
        oldDomainNames.forEach((domainName) => {
            this.cookieService.delete('AccessToken', '/', domainName);
            this.cookieService.delete('IdToken', '/', domainName);
            this.cookieService.delete('RefreshToken', '/', domainName);
            this.cookieService.delete('lang', '/', domainName);
            this.cookieService.delete('segmentId', '/', domainName);
        });
    }
}
