import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { PermissionEnum } from '../enums/permission.enum';
import { Paging } from '../../pages/equipment-list/components/list/list.component';
import { TableDataSource } from '../directives/table-datasource';

export interface TableColumn {
    columnDef: string;
    show: boolean;
    title?: string;
    permission?: PermissionEnum;
}

@Component({
    template: '',
})
export abstract class BaseTableComponent<T> implements Paging, OnInit {
    @ViewChild(CdkVirtualScrollViewport, { static: true })
    viewport?: CdkVirtualScrollViewport;

    @Input() page = 1;
    @Input() limit: number = 50;

    dataSource!: TableDataSource<T>;
    sticky = false;
    loading = false;
    offset$!: Observable<number>;
    pagingChange$: BehaviorSubject<Paging> = new BehaviorSubject({
        page: this.page,
        limit: this.limit,
    });
    protected totalCount!: number;

    public scrollPosition = 0;

    ngOnInit(): void {
        this.dataSourceInit();
        this.setOffset();
    }

    resetPaging(): void {
        this.pagingChange$.next({
            page: (this.page = 1),
            limit: this.limit,
        });
    }

    private dataSourceInit() {
        if (this.dataSource) return;

        this.dataSource = new TableDataSource<T>({
            initialData: [],
            viewport: this.viewport,
        });
    }

    private setOffset(): void {
        if (!this.viewport) {
            this.offset$ = new Observable<number>((observer) => observer.next(0));
            return;
        } 

        this.offset$ = this.viewport.renderedRangeStream.pipe(
            map(() => {
                const offset = this.viewport?.getOffsetToRenderedContentStart();
                return offset != null ? -offset : 0; // Handle null and undefined case
            })
        );
    }

    abstract loadDataUntil(nextPaging: Paging): boolean;

    nextBatch(scrollPosition: number) {
        this.scrollPosition = scrollPosition;
        if (!this.sticky) this.sticky = true;
        const buffer = 20;
        const range = this.viewport?.getRenderedRange();
        const end = range?.end || 0;

        if (this.dataSource?.allData?.length > 0 && !this.loading) {
            if (end + buffer > this.dataSource.length) {
                const nextPage = {
                    page: this.page + 1,
                    limit: this.limit,
                };
                if (!this.loadDataUntil(nextPage)) return;
                this.pagingChange$.next({
                    page: ++this.page,
                    limit: this.limit,
                });
                this.loading = true;
            }
        }
    }
}
