import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { CookieService } from 'ngx-cookie-service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
    getUserInfo,
    getUserInfoSuccess,
    logoutUser,
    syncAuthStatus,
} from './user.actions';
import { CoreConfigService } from '@sharemactechhub/frontend-library/config-loader';
import { User } from '../../shared/interfaces/user.interface';
import { SiteConfigurationSchema } from '../../shared/interfaces/config.interface';
import { UserService } from '../../services/user.service';

@Injectable()
export class UserEffects implements OnInitEffects {
    private readonly hasIdToken: boolean = this.cookieService.check('IdToken');
    protected logout$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(logoutUser),
                map(() => this.userService.logoutUser()),
                filter((r) => r),
                tap(() => {
                     window.location.href =
                        this.coreConfig.select((r) => r.redirects.login.url) +
                        encodeURIComponent(window.location.href);
                })
            ),
        { dispatch: false }
    );

    protected myData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getUserInfo),
            switchMap(() => this.userService.getUserInfo()),
            switchMap((content: User) => [
                getUserInfoSuccess({ user: content }),
            ])
        )
    );
    constructor(
        private readonly actions$: Actions,
        private readonly userService: UserService,
        private readonly cookieService: CookieService,
        private readonly coreConfig: CoreConfigService<SiteConfigurationSchema>
    ) {}

    ngrxOnInitEffects() {
        return syncAuthStatus({ hasIdToken: this.hasIdToken });
    }
}
