import {
    CollectionViewer,
    DataSource,
    ListRange,
} from '@angular/cdk/collections';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

interface DataSourceConfig<T> {
    initialData: T[];
    viewport?: CdkVirtualScrollViewport;
}

interface DataSourceCRUD<T = unknown> {
    update: (item: T | T[], key: keyof T) => boolean;
    add: (item: T | T[], index?: number) => void;
    delete: (callback: (value: T, index: number) => boolean) => void;
}

export class TableDataSource<T>
    extends DataSource<T>
    implements DataSourceCRUD<T>
{
    readonly queryData: Observable<T[]>;
    private readonly _queryData = new BehaviorSubject<T[]>([]);
    private readonly visibleData: Observable<T[]>;
    private _data: T[];

    get allData(): T[] {
        return this._data.slice();
    }
    set allData(data: T[]) {
        this._data = data;
        this._queryData.next(data);
    }
    get data(): T[] | undefined {
        let data: T[] | undefined;
        this.visibleData.subscribe((d) => (data = d)).unsubscribe();
        return data;
    }

    get length() {
        return this._data.length || 0;
    }

    constructor(
        { initialData, viewport }: DataSourceConfig<T> = { initialData: [] }
    ) {
        super();
        this._data = initialData;
        this.queryData = this._queryData.asObservable();
        this._queryData.next(initialData);
        const sliced = combineLatest([
            this._queryData,
            viewport?.renderedRangeStream.pipe(startWith({} as ListRange)) ?? of({} as ListRange),
        ]).pipe(
            map(([data, range]) =>
                range.start == null || range.end == null ? data : data.slice(range.start, range.end)
            )
        );
        this.visibleData = sliced.pipe(
            shareReplay({
                bufferSize: 1,
                refCount: false,
            })
        );
    }

    connect(_collectionViewer: CollectionViewer): Observable<T[]> {
        return this.visibleData;
    }

    disconnect(_collectionViewer: CollectionViewer): void {
        this._queryData.complete();
    }

    update(item: T | T[], key: keyof T): boolean {
        if (!item) return false;

        let result = true;
        if (Array.isArray(item)) {
            item.forEach((singleItem) => {
                result = result && this.updateItem(singleItem, key);
            });
        } else {
            result = this.updateItem(item, key);
        }
        return result;
    }

    add(item: T | T[], index?: number): void {
        const idx = index ?? this._data.length;
        if (Array.isArray(item)) {
            this._data.splice(idx, 0, ...item);
        } else {
            this._data.splice(idx, 0, item);
        }
        this.allData = [...this._data];
    }

    delete(callback: (value: T, index: number) => boolean): void {
        if (!this.data) return; // Early return if `this.data` is undefined
        
        const findedIndexes: number[] = [];
        this.data.forEach((item, index) => {
            if (callback(item, index)) {
                findedIndexes.push(index);
            }
        });
        findedIndexes.forEach((index: any) => {
            this.data?.splice(index, 1);
        });
        this.allData = this.data;
    }

    private updateItem(item: T, key: keyof T): boolean {
        if (!this.data) {
            return false;
        }
    
        const elementIndex = this.data.findIndex((singleItem) => {
            const itemKeyValue = item[key];
            const singleItemKeyValue = singleItem[key];
            return itemKeyValue !== undefined && singleItemKeyValue !== undefined && singleItemKeyValue === itemKeyValue;
        });
    
        if (elementIndex === -1) {
            return false;
        }
        if (this.data) {
            this.data[elementIndex] = {
                ...this.data[elementIndex],
                ...item,
            };
        }
        return true;
    }
    
}
