import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { Token } from '../interfaces/token.interface';
import { getDomain } from '../utils/get-domaun.util';
import { logoutUser } from '../../store/user/user.actions';
import { MicroServicesEnum } from '../enums/microservice.enu';
import { ApiGatewayService } from '../../services/api-gateway.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    //edited
    private isRefreshing = false;
    private readonly refreshTokenSubject: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);

    constructor(
        private readonly translate: TranslateService,
        private readonly cookieService: CookieService,
        private readonly store: Store,
        private readonly router: Router,
        public http: ApiGatewayService,
    ) {}

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `${token}`,
            },
        });
    }

    private getRefreshToken() {
        return this.cookieService.get('RefreshToken');
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.refreshToken().pipe(
                switchMap((token: Token) => {
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(token.AccessToken);
                    return next.handle(
                        this.addToken(request, token.AccessToken)
                    );
                })
            );
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((jwt) => {
                    return next.handle(this.addToken(request, jwt));
                })
            );
        }
    }

    private refreshToken() {
        let refreshtoken = { refreshToken: this.getRefreshToken() };
        return this.http
            .post<any>(
                `oauth2/refresh-token`,
                refreshtoken,
                null,
                MicroServicesEnum.userApiSubPath
            )
            .pipe(
                tap((tokens) => {
                    this.storeJwtToken(tokens.IdToken, tokens.AccessToken);
                }),
                catchError((error) => {
                    this.store.dispatch(logoutUser());
                    return throwError(error);
                })
            );
    }

    private storeJwtToken(id_token: string, access_token: string) {
        const domain = getDomain(window.location.hostname, 4);
        this.cookieService.set('IdToken', id_token, 12312321, '/', domain);
        this.cookieService.set('AccessToken', access_token, 12312321, '/', domain);
    }

    intercept(req: any, next: any) {
        let tokenizeReq = req.clone({
            setHeaders: {
                Authorization: `${this.cookieService.get('AccessToken')}`,
                'accept-language': this.translate.currentLang,
                'accept-timeZone': (
                    new Date().getTimezoneOffset() / -60
                ).toString(),
            },
        });
        return next.handle(tokenizeReq).pipe(
            catchError((error: any) => {
                if (
                    error instanceof HttpErrorResponse &&
                    error.status === 401
                ) {
                    return this.handle401Error(req, next);
                } else if (
                    error instanceof HttpErrorResponse &&
                    error.status === 403
                ) {
                    this.router.navigate(['/exception', 'access-denied']);
                    return throwError(() => error);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }
}

export const AUT_INTERCEPTOR_PROVIDER = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
};
