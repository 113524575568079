<div class="list">
    <p class="list__title"> {{'search_by_equipment' | translate}}</p>
    <p class="list__instruction"> {{'search_by_title_invnumber' | translate}}</p>

    <shl-form-field shlSize="md">
        <input 
        shlInput
        [formControl]="searchControl" 
        [placeholder]="'E.G. Caterpillar F501' | translate">
        <!-- <img src="assets/img/location.svg" alt="location" shlPrefix /> -->
    </shl-form-field>

    <sam-list [searchParams$]="searchParams$"></sam-list>
</div>