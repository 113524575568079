<div class="general-info">
  <div class="general-info__wrapper">
    <div class="general-info__header">
      <img class="general-info__icon" src="../../../../../assets/images/1.svg" alt="">
      <span class="general-info__title">{{'general_info' | translate}}</span>
    </div>
    
    <span class="divider"></span>

    <div class="general-info__item" [ngClass]="{'not-indicated': !equipment()?.brand?.name}">
      <span  class="general-info__item-title"> {{'brand'|translate}}</span>
      @if (equipment()?.brand?.name) {
        {{equipment()?.brand?.name}}
      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

    <div class="general-info__item" [ngClass]="{'not-indicated': !equipment()?.model?.name}">
      <span class="general-info__item-title"> {{'model'|translate}}</span>

      @if (equipment()?.model?.name) {
        {{equipment()?.model?.name}}
      }@else {
        {{'not_indicated' | translate}}
      }
    </div>


    <div class="general-info__item" [ngClass]="{'not-indicated': !equipment()?.year}">
      <span class="general-info__item-title"> {{'year'|translate}}</span>
      @if (equipment()?.year) {
        {{equipment()?.year}}
      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

    <div class="general-info__item" [ngClass]="{'not-indicated': !equipment()?.category?.name}">
      <span class="general-info__item-title"> {{'category'|translate}}</span>
      @if (equipment()?.category?.name) {
        {{equipment()?.category?.name}}
      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

  </div>

</div>