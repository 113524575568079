import { gql } from 'apollo-angular';

export const CURRENT_DEVICE_DATA = gql`
    query currentDeviceData($type: String!, $id: String!) {
        currentDeviceData(type: $type, id: $id) {
            lastSeen
            fieldTS {
                boardId
                simCardIMSI
                simCardProvider
                powerVoltage
                batteryVoltage
                batteryCapacityPercent
                latitude
                longitude
                altitude
                gpsTime
                gpsDirection
                gpsSpeed
                acceleratorX
                acceleratorY
                acceleratorZ
                numberOfSatellites
                batteryCurrent
                vibrationVal
                firmwareVersion
                workingHours
                batteryCapacityValue
                batteryCapacityDelta
                linkSignalQuality
                systemStatus
                cumulativeIdleHours
                defRemaining
                odometerDistance
                fuelConsumed
                fuelRemaining
                engineNumber
                defTankCapacity
                fuelTankCapacity
                simImsi
                canConnectionStatus
                canEngineAllStartsCount
                canWorkingHours
                canEngineRpm
                canEngineTemperature
                batteryChargingStatus
                canOdometerDistance
                canGpsSpeed
                din
                din_1
                din_2
                din_3
                dout
                dout_1
                dout_2
                dout_3
            }
            deviceData {
                boardId
                latitude
                longitude
                simCardIMSI
                simCardProvider
                powerVoltage
                batteryVoltage
                batteryCapacityPercent
                altitude
                gpsTime
                gpsDirection
                gpsSpeed
                acceleratorX
                acceleratorY
                acceleratorZ
                numberOfSatellites
                batteryCurrent
                vibrationVal
                firmwareVersion
                workingHours
                batteryCapacityValue
                batteryCapacityDelta
                linkSignalQuality
                systemStatus
                engineOn
                cumulativeIdleHours
                defRemaining
                odometerDistance
                fuelConsumed
                fuelRemaining
                engineNumber
                defTankCapacity
                fuelTankCapacity
                simImsi
                canConnectionStatus
                canEngineAllStartsCount
                canWorkingHours
                canEngineRpm
                canEngineTemperature
                batteryChargingStatus
                canOdometerDistance
                canGpsSpeed
                din
                din_1
                din_2
                din_3
                dout
                dout_1
                dout_2
                dout_3
                canEngineCoolantTemperature
                canFuelLevel
                obdConnectedStatus
                vehicleVin
            }
        }
    }
`;

/**
 * @description gets telematics data history as a Observable stream with provided node fields
 * @param $type company identifier, something like "Sharemag-G2" (equipment.field_beacon_vendor), not null
 * @param $id sensor identifier, something like "SM-TEL-001" (equipment.field_beacon_id), not null
 */
export const DEVICE_DATA_SUBSCRIPTION = gql`
    subscription ($type: String!, $id: String!, $authorization: String!) {
        deviceDataUpdates(type: $type, id: $id, authorization: $authorization) {
            boardId
            latitude
            longitude
            simCardIMSI
            simCardProvider
            powerVoltage
            engineOn
            batteryVoltage
            batteryCapacityPercent
            altitude
            gpsTime
            gpsDirection
            gpsSpeed
            acceleratorX
            acceleratorY
            acceleratorZ
            numberOfSatellites
            batteryCurrent
            firmwareVersion
            workingHours
            batteryCapacityValue
            batteryCapacityDelta
            linkSignalQuality
            systemStatus
        }
    }
`;

/**
 * @description gets telematics data history with provided node fields
 * @param $type company identifier, something like "Sharemag-G2" (equipment.field_beacon_vendor), not null
 * @param $id sensor identifier, something like "SM-TEL-001" (equipment.field_beacon_id), not null
 * @param $startTS filter start date, not null
 * @param $endTS filter end date, not null
 * @param $first behaves like count (total), must be used with @param $after, if $after is not provided, then consideres as first page
 * @param $last behaves like count (total), must be used with @param $before, if $before is not provided, then consideres as last page
 * @param $before is cursor, optimized page identifier; must be used with @param $last
 * @param $after is cursor, optimized page identifier; must be used with @param $first
 */
export const TRACKING_FULL_HISTORY = gql`
    query deviceHistory(
        $type: String!
        $id: String!
        $startTS: String!
        $endTS: String
        $first: Int
        $after: String
    ) {
        deviceHistory(
            type: $type
            id: $id
            startTS: $startTS
            endTS: $endTS
            first: $first
            after: $after
        ) {
            edges {
                cursor
                node {
                    timestamp
                    batteryCapacityPercent
                    batteryVoltage
                    powerVoltage
                    latitude
                    longitude
                    altitude
                    numberOfSatellites
                    vibrationVal
                    acceleratorX
                    acceleratorY
                    acceleratorZ
                    workingHours
                    engineOn
                    accuracy
                    reboots
                    rsrp
                    rsrq
                    rssi
                    sinr
                    cellid
                    simImsi
                    linkSignalQuality
                    firmwareVersion
                    fuelRemaining
                    cumulativeIdleHours
                    fuelConsumed
                    canConnectionStatus
                    canEngineAllStartsCount
                    canWorkingHours
                    canEngineRpm
                    canEngineTemperature
                    batteryChargingStatus
                    canOdometerDistance
                    canGpsSpeed
                    din
                    din_1
                    din_2
                    din_3
                    dout
                    dout_1
                    dout_2
                    dout_3
                    canEngineCoolantTemperature
                    canFuelLevel
                    obdConnectedStatus
                    vehicleVin
                }
            }
            pageInfo {
                startCursor
                endCursor
                hasNextPage
            }
        }
    }
`;

/**
 * @description gets telematics data history only for provided field
 * @param $type company identifier, something like "Sharemag-G2" (equipment.field_beacon_vendor), not null
 * @param $id sensor identifier, something like "SM-TEL-001" (equipment.field_beacon_id), not null
 * @param $startTS filter start date, not null
 * @param $endTS filter end date, not null
 * @param $first behaves like count (total), must be used with @param $after, if $after is not provided, then consideres as first page
 * @param $last behaves like count (total), must be used with @param $before, if $before is not provided, then consideres as last page
 * @param $before is cursor, optimized page identifier; must be used with @param $last
 * @param $after is cursor, optimized page identifier; must be used with @param $first
 */
export const TRACKING_FIELD_HISTORY = (field: string) => {
    return gql`
    query deviceHistory($type: String!, $id: String!, $startTS: String!, $endTS: String, $first: Int, $after: String) {
      deviceHistory(type: $type, id: $id, startTS: $startTS, endTS: $endTS, first: $first, after: $after) {
        edges {
            cursor,
            node {
                ${field},
                timestamp
            }
        },
        pageInfo {
            startCursor,
            endCursor,
            hasNextPage
        }
      }
    }
  `;
};

export const DAILY_AGGREGATES_BY_START_DATE = gql`
    query dailyAggregatesByStartDate(
        $type: String!
        $id: String!
        $startTS: String!
        $numberOfDays: Int!
        $numberOfDaysQualifier: TimeIntervalType
        $continuousDates: Boolean
    ) {
        dailyAggregatesByStartDate(
            type: $type
            id: $id
            startTS: $startTS
            numberOfDays: $numberOfDays
            numberOfDaysQualifier: $numberOfDaysQualifier
            continuousDates: $continuousDates
        ) {
            date
            minutesWorked
            minutesIdle
        }
    }
`;

export const QUERY_DEVICE_FILTERED_HISTORY = (field: string) => gql`
  query queryDeviceFilteredHistory($type: String!, $id: String!, $startTS: String!, $endTS: String, $filtrationFactor: Int!) {
    queryDeviceFilteredHistory(type: $type, id: $id, startTS: $startTS, endTS: $endTS, filtrationFactor: $filtrationFactor) {
      ${field},
      timestamp
    }
  }
`;

export const SIM_CARD_IMSI_INFORMATION = gql`
    query IMSIInformation($imsi: String!) {
        IMSIInformation(imsi: $imsi) {
            mcc
            mnc
            brand
            operator
            countryCode
        }
    }
`;
