import { Action, createReducer, on } from '@ngrx/store';
import * as authActions from './user.actions';
import { User } from '../../shared/interfaces/user.interface';

export enum AuthStatus {
    Undefined,
    Authorized,
    Unauthorized,
}

export interface UserState {
    authStatus: AuthStatus;
    user?: User;
}
export const userFeatureKey = 'user';

export const UserInitialState: UserState = {
    authStatus: AuthStatus.Undefined,
};

const reducer = createReducer(
    UserInitialState,
    on(authActions.syncAuthStatus, (draft, action) => {
        if (!action.hasIdToken) return draft;
        return {
            ...draft,
            authStatus: AuthStatus.Authorized,
        };
    }),
    on(authActions.getUserInfoSuccess, (state, action) => {
        return {
            ...state,
            user: action.user,
        };
    })
);

export const reducerUser = (state: UserState = UserInitialState, action: Action) => {
    return reducer(state, action);
};
