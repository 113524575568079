import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { CoreConfigService } from '@sharemactechhub/frontend-library/config-loader';
import { Observable } from 'rxjs';
import { SiteConfigurationSchema } from '../shared/interfaces/config.interface';

export enum MicroServicesEnum {
    projApiSubPath = 'proj-api',
    userApiSubPath = 'user-api',
    equipmentsApiSubPath = 'eq-api',
    orgApiSubPath = 'org-api',
    webApi = 'web-api',
    adminSubPath = 'admin',
    mediaApiSubPath = 'media-api',
    eqposSubPath = 'eqpos-api',
    hrPlannerSubPath = 'hr-planner',
    kologne = 'kologne',
    lectura = 'lectura-api',
    transportations = 'transportations',
    telematicsApiSubPath = 'telematics-api',
    webBFF = 'web-bff',
    workingHours = 'working-hours-api',
    moveAwayBFFApiSubPath = 'move-away/bff',
    moveAwayApiSubPath = 'move-away',
    chargingApiSubPath = 'charging-api',
    positioningApiSubPath = 'positioning-api',
}

@Injectable()
export class ApiGatewayService {
    private readonly http = inject(HttpClient);
    private readonly coreConfig: CoreConfigService<SiteConfigurationSchema> =
        inject(CoreConfigService);

    delete<T = any>(
        path?: string,
        params?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath,
        body?: any
    ): Observable<T> {
        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
            params: httpParams,
        };

        return this.http.delete<any>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            options
        );
    }

    get<T = any>(
        path: string,
        params?: any,
        microService:
            | MicroServicesEnum
            | string = MicroServicesEnum.projApiSubPath,
        responseType?: any,
        extraHeaders?: any
    ): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                ...extraHeaders,
            }),
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key] || typeof params[key] === 'number') {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.http.get<T>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            { ...options, params: httpParams, responseType: responseType }
        );
    }

    getArrayBuffer(
        url: string,
        body: object = {},
        params?: any,
        headers?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath
    ): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        if (headers) {
            Object.keys(headers).forEach((key) => {
                if (headers[key]) {
                    options.headers = options.headers.set(key, headers[key]);
                }
            });
        }

        return this.http.post(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${url}`,
            body,
            {
                ...options,
                params: httpParams,
                responseType: 'text',
            }
        );
    }

    patch<T = any>(
        path: string,
        body: object = {},
        params?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath,
        formData = 'json'
    ): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                // 'Access-Control-Max-Age': '0'
            }),
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.http.patch<T>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            formData === 'json' ? JSON.stringify(body) : body,
            formData === 'json'
                ? {
                      ...options,
                      params: httpParams,
                  }
                : {}
        );
    }

    post<T = any>(
        path: string,
        body: any,
        params?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath,
        formData = 'json',
        extraHeaders?: any,
        responseType?: any
    ): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                ...extraHeaders,
            }),
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.http.post<T>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            formData === 'json' ? JSON.stringify(body) : body,
            formData === 'json'
                ? {
                      ...options,
                      params: httpParams,
                      responseType: responseType,
                  }
                : {}
        );
    }

    put<T = any>(
        path: string,
        body: any,
        params?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath,
        formData = 'json'
    ): Observable<T> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.http.put<T>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            formData === 'json' ? JSON.stringify(body) : body,
            formData === 'json'
                ? {
                      ...options,
                      params: httpParams,
                  }
                : {}
        );
    }

    upload(
        path: string,
        body: any,
        params?: any,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath
    ): Observable<any> {
        const options = {
            // headers: new HttpHeaders({
            //   'Content-Type': 'application/json'
            // })
        };

        let httpParams = new HttpParams();

        if (params) {
            Object.keys(params).forEach((key) => {
                if (params[key]) {
                    httpParams = httpParams.append(key, params[key]);
                }
            });
        }

        return this.http.post(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${path}`,
            body,
            {
                ...options,
                params: httpParams,
            }
        );
    }

    downloadPdf<T>(
        url: string,
        body: T,
        microService: MicroServicesEnum = MicroServicesEnum.projApiSubPath
    ): Observable<BlobPart> {
        const headers = { responseType: 'blob' as 'json' };

        return this.http.post<BlobPart>(
            `${this.coreConfig.select(
                (r) => r.core.apiGatewayUrl
            )}/${microService}/${url}`,
            body,
            headers
        );
    }
}
