import { RouterModule, Routes } from '@angular/router';
import { canActivateFn } from './shared/guards';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { EquipmentListComponent } from './pages/equipment-list/equipment-list.component';
import { EquipmentDetailComponent } from './pages/equipment-detail/equipment-detail.component';

export const ROUTES: Routes = [
    {
        path: '',
        title: 'Equipment Viewer',
        redirectTo: 'list',
        pathMatch: 'full',
    },
    {
        path: 'list',
        component: EquipmentListComponent,  
        canActivate: [canActivateFn],
        title: 'Equipment Viewer',
        data: { animation: 'List' }
    },
    {
        path: 'details/:id',
        component: EquipmentDetailComponent,  
        canActivate: [canActivateFn], 
        title: 'Equipment Viewer',
        data: { animation: 'Detail' }
    },
];
@NgModule({
    imports: [RouterModule.forRoot(ROUTES, {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
    })],
    exports: [RouterModule],
})
export class RoutingModule {}