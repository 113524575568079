import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { PdfButtonComponent } from '../../../../shared/components/pdf-button/pdf-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'sam-documents',
  imports: [CommonModule, PdfButtonComponent, TranslateModule],
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentsComponent {
  equipment = input<any>();

  public get isEmpty(): boolean {
    const { equipmentDocs } = this.equipment() ?? {};
    return !equipmentDocs && this.documents?.length > 0;
  }
  public get documents() {
    const techDocs = this.equipment()?.equipmentTechDocs;
    if (!techDocs) return []
    return Object.values(techDocs as object).filter((doc) => Object.keys(doc as object)?.length > 0);
  }

}
