import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Media } from '../interfaces/media.interface';
import { ImageSize } from '../enums/media.enum';

@Pipe({ name: 'mainImage' })
export class MainImagePipe implements PipeTransform {
    public transform(
        value: Media[],
        imgsize = ImageSize.Original,
        defaultImage = './assets/default-equipment-icon.png'
    ) {
        const myImgUrl: string = defaultImage;
        // || value.includes(undefined) || value.includes(null)
        if (!value) {
            return myImgUrl;
        }
        if (typeof value === 'string') {
            return value;
        }
        const clonedValue = [...value];

        if (value.length > 1) value = value.filter((image) => image.main);

        if (value.length > 0) {
            const mainImageObject = value[0];
            if (!mainImageObject?.files) return (mainImageObject as unknown as { path: string })?.path;
            let array = mainImageObject?.files;
            let item = array?.filter((file) => file.size === imgsize)[0];
            item ||
                (item = array?.filter(
                    (file) => file.size === ImageSize.Original
                )[0]);
            return item?.path;
        } else {
            if (
                clonedValue.length > 0 &&
                Object.prototype.hasOwnProperty.call(clonedValue[0], 'path')
            ) {
                return (clonedValue[0] as unknown as { path: string })['path'];
            }
            return myImgUrl;
        }
    }
}

@NgModule({
    imports: [CommonModule],
    exports: [MainImagePipe],
    declarations: [MainImagePipe],
    providers: [MainImagePipe],
})
export class MainImagePipeModule {}
