import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DailyAggregate } from '../../../../shared/interfaces';
import { map } from 'rxjs';
import { TimeIntervalType } from '../../../../shared/enums/time-interval-type.enum';
import { TrackingFacadeService } from '../../../../services/tracking.facade';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { EquipmentFacadeService } from '../../../../services/equipment.facade.service';
import { BeaconVendorConfig } from '../../../../shared/interfaces/beacon-vendor-config.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, NgChartsModule],
  selector: 'sam-operating-hours',
  templateUrl: './operating-hours.component.html',
  styleUrls: ['./operating-hours.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OperatingHoursComponent implements OnInit {
  equipment = input<any>();
  public weekNumberTitle!: string | number;
  private activeDate!: Date;
  public dailyAggregates!: Array<DailyAggregate>;
  public barChartData: any[] = [];
  public barChartLabels: any[] = [];
  public barChartType: any = 'bar';
  public barChartLegend = true;
  private beaconVendorConfig!: BeaconVendorConfig;
  public barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: {
            ticks: {
                beginAtZero: true,
                callback: (value: any) => {
                    return `${value
                        .toString()
                        .replace('.', ',')} ${this.translateService.instant(
                        'op_hours_label'
                    )}`;
                },
            },
            scaleLabel: {
                display: true,
                labelString: 'Hours',
                format: { style: 'currency' },
            },
        },
        xAxes: {
            scaleLabel: {
                display: true,
                labelString: 'Days',
            },
        },
    },

    plugins: {
        dataLabels: {
            anchor: 'end',
            align: 'end',
        },
        legend: {
            display: true,
        },
        tooltip: {
            enabled: true,
            intersect: true,
            callbacks: {
                label: (data: any) => {
                    const currentValue: any =
                        data.dataset.data[data.dataIndex];
                    const rhours = Math.floor(currentValue);
                    const minutes = (currentValue - rhours) * 60;
                    const rminutes = Math.round(minutes);
                    const isWorkingHours = data.dataset.isWorkingHours;
                    const formattedTime = `${rhours} ${this.translateService.instant(
                        'op_hours_label'
                    )} ${rminutes} ${this.translateService.instant(
                        'op_minutes_label'
                    )}`;
                    if (rhours > 16) {
                        return this.translateService.instant(
                            isWorkingHours
                                ? '16_hours_working_text'
                                : '16_hours_idle_text'
                        );
                    } else {
                        return `${data.label} ${formattedTime}`;
                    }
                },
            },
        },
        datalabels: {
            align: 'start',
            anchor: 'end',
            borderRadius: 70,
            borderColor: 'white',
            borderWidth: 1,
            color: 'white',
            font: {
                weight: 'normal',
                size: 5,
            },
            padding: 5,
            formatter: (value: any, context: any) => {
                if (!context.active) {
                    return null;
                }
                if (value >= 16) {
                    return '!';
                } else {
                    return null;
                }
            },
        },
    },
};


private readonly translateService = inject(TranslateService);
private readonly trackingFacadeService = inject(TrackingFacadeService);
private readonly equipmentFacadeService = inject(EquipmentFacadeService);
private readonly cdr = inject(ChangeDetectorRef);
private readonly destroyRef = inject(DestroyRef);


  constructor() { }

  ngOnInit() {
    this.equipmentFacadeService
    .getBeaconVendorConfig<BeaconVendorConfig>()
    .subscribe((config: BeaconVendorConfig) => {
        this.beaconVendorConfig = config;
        this.setActiveDate();
        this.subToEquipment();
    });
  }
  private subToEquipment(): void {
     if (this.beaconVendorConfig[this.equipment()?.beaconVendor]) {
      const config =
          this.beaconVendorConfig[this.equipment().beaconVendor];
      if (
          config.aggregations.workingMinutes &&
          !this.barChartData.length
      ) {
          this.barChartData.push({
              data: [],
              label: this.translateService.instant(
                  'working_hours'
              ),
              backgroundColor: '#ec0808',
              hoverBackgroundColor: '#ec0808',
          });
      }
      if (
          config.aggregations.idleMinutes &&
          this.barChartData.length !== 2
      ) {
          this.barChartData.push({
              data: [],
              label: this.translateService.instant('idle_hours'),
              backgroundColor: '#459af7',
          });
      }
  }

  this.getDailyAggregates(this.activeDate);

  this.cdr.detectChanges();
}


  public slide(status: boolean): void {
    if (status) {
        this.setActiveDate(
            new Date(
                new Date(this.activeDate).setDate(
                    new Date(this.activeDate).getDate() + 7
                )
            )
        );
    } else {
        this.setActiveDate(
            new Date(
                new Date(this.activeDate).setDate(
                    new Date(this.activeDate).getDate() - 1
                )
            )
        );
    }
    this.getDailyAggregates(this.activeDate);
}

private getDailyAggregates(date: Date): void {
  if (!this.equipment()?.beaconVendor || !this.equipment()?.beaconId) {
      return;
  }
  this.trackingFacadeService
      .getTrackingDailyAggregates(
          this.equipment().beaconVendor,
          this.equipment().beaconId,
          this.getTimeStampOfMonthsLastMonday(date),
          7,
          TimeIntervalType.DAY,
          true
      )
      .valueChanges.pipe(
          map((x: any) =>
              x?.data ? x?.data?.dailyAggregatesByStartDate : null
          )
      )
      .subscribe((dailyAggregatesFirstPart: any) => {
          this.updateChartMinAndMax(dailyAggregatesFirstPart);
          if (
              dailyAggregatesFirstPart &&
              dailyAggregatesFirstPart.length > 0
          ) {
              this.dailyAggregates = dailyAggregatesFirstPart;
          } else {
              this.dailyAggregates = [];
          }
          this.randomize();
      });
}

public randomize(): void {
  if (this.barChartData.length) {
      this.barChartData.map((item) => {
          item.data = [];
          return item;
      });
      this.barChartData[0].data = [];
  }
  if (this.barChartData.length === 2) {
      this.barChartData[1].data = [];
  }
  this.barChartLabels = [];
  this.dailyAggregates.forEach((item) => {
      const workingHours =
          Math.round((Math.abs(item.minutesWorked) / 60) * 100) / 100;
      const idleHours =
          Math.round((Math.abs(item.minutesIdle) / 60) * 100) / 100;

      if (this.barChartData.length) {
          this.barChartData[0].data.push(workingHours);
      }
      if (this.barChartData.length === 2) {
          this.barChartData[1].data.push(idleHours);
      }
  });
  for (let i = 0; i < 7; i++) {
      const date = new Date(
          new Date(
              new Date(this.activeDate).setDate(
                  new Date(this.activeDate).getDate() + i
              )
          )
      );
      this.barChartLabels.push(
          `${`0${new Date(date).getDate()}`.slice(-2)}.${`0${
              new Date(date).getMonth() + 1
          }`.slice(-2)}`
      );
  }

  this.weekNumberTitle = this.weekNumber(this.activeDate);
  this.cdr.detectChanges()
}

private weekNumber(dt: Date): number {
  const tdt: any = new Date(dt.valueOf());
  const dayn = (dt.getDay() + 6) % 7;
  tdt.setDate(tdt.getDate() - dayn + 3);
  const firstThursday = tdt.valueOf();
  tdt.setMonth(0, 1);
  if (tdt.getDay() !== 4) {
      tdt.setMonth(0, 1 + ((4 - tdt.getDay() + 7) % 7));
  }
  return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

private setActiveDate(date = new Date()): void {
  function getMonday(d: any) {
      d = new Date(d);
      const day = d.getDay(),
          diff = d.getDate() - day + (day === 0 ? -6 : 1);
      return new Date(d.setDate(diff));
  }

  this.activeDate = getMonday(date);
}


private getTimeStampOfMonthsLastMonday(dateWithFirstDay: Date): number {
  const date = new Date(dateWithFirstDay.toString());
  if (date.getDay() !== 1) {
      date.setDate(date.getDate() - (date.getDay() - 1));
  }
  return this.convertTimeZone(date, 'Europe/Berlin').getTime();
}

private convertTimeZone(date: Date, tzString: string): Date {
  return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
          'en-US',
          { timeZone: tzString }
      )
  );
}


private updateChartMinAndMax(dailyAggregatesFirstPart: any): void {
  const isDataAvilable = !!(dailyAggregatesFirstPart || []).filter(
      (item: any) => item.minutesIdle || item.minutesWorked
  ).length;
  if (!isDataAvilable) {
      this.barChartOptions.scales.yAxes.min = 1;
      this.barChartOptions.scales.yAxes.max = 8;
  } else {
      this.barChartOptions.scales.yAxes.min = null;
      this.barChartOptions.scales.yAxes.max = null;
  }
}


}
