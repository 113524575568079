<div class="header">

  <div class="header__logo">
    @if (isDetailPage$|async) {
      <img [routerLink]="['/']"  src="../assets/images/arrow-left.svg" alt="navigaion">
    }
  </div>

  <img [routerLink]="['/']" class="wrapper__logo" src="../assets/images/logo.svg" alt="logo">

  <img (click)="logOut()" class="wrapper__logo" src="../assets/images/logout.svg" alt="logOut">

</div>
