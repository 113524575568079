import { Injectable } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { map, Observable, Subject } from 'rxjs';

import { EquipmentTrackingService } from './equipment-tracking.service';
import { DailyAggregate, TrackingDeviceHistory, TrackingResult } from '../shared/interfaces';
import { TrackingService } from './tracking.service';
import { EquipmentMultiInfo } from '../shared/interfaces/equipment-multi-info.interface';
import { TimeIntervalType } from '../shared/enums/time-interval-type.enum';

@Injectable()
export class TrackingFacadeService {
    readonly currentTrackingData$ =
        this.equipmentTrackingService.currentTrackingData;
    readonly getEquipment$ = this.equipmentTrackingService.getEquipment$();
    readonly trackingFullHistoryData$ =
        this.equipmentTrackingService.trackingFullHistoryData;
    readonly trackingData$ = this.equipmentTrackingService.trackingData;

    private readonly zoomInMapModal$: Subject<TrackingResult> = new Subject();

    public zoomInMapModalObserver(): Observable<TrackingResult> {
        return this.zoomInMapModal$.asObservable();
    }

    constructor(
        private readonly trackingService: TrackingService,
        private readonly equipmentTrackingService: EquipmentTrackingService
    ) {}

    getEquipmentMultiInfo(
        equipmentIds: Array<string>
    ): Observable<EquipmentMultiInfo[]> {
        return this.trackingService.getEquipmentMultiInfo<EquipmentMultiInfo[]>(
            equipmentIds
        );
    }

    setEquipment(equipment: any): void {
        this.equipmentTrackingService.setEquipment(equipment);
    }

    loadTrackingData(type: string, id: string): void {
        this.equipmentTrackingService.loadTrackingData(type, id);
    }
    getWarehouseDetails(id: string): Observable<any> {
        return this.trackingService
            .getWarehouseDetails(id)
            .pipe(map((item: any) => item.warehouse));
    }

    getProjectDetails(projectId: string) {
        return this.trackingService.getProjectDetails(projectId);
    }

    loadTrackingFullHistory(
        type: string,
        id: string,
        startTS: string,
        endTS: string,
        first?: number,
        after?: string
    ): void {
        return this.equipmentTrackingService.loadTrackingFullHistory(
            type,
            id,
            startTS,
            endTS,
            first,
            after
        );
    }

    getTrackingFieldHistory(
        fieldName: string,
        type: string,
        id: string,
        startTS: number,
        endTS: number,
        first?: number,
        after?: string
    ): QueryRef<{ deviceHistory: TrackingDeviceHistory }> {
        return this.equipmentTrackingService.getTrackingFieldHistory(
            fieldName,
            type,
            id,
            startTS,
            endTS,
            first,
            after
        );
    }

    getTrackingDeviceFilteredHistory(
        fieldName: string,
        type: string,
        id: string,
        startTS: number,
        endTS: number,
        filtrationFactor: number
    ) {
        return this.equipmentTrackingService.getTrackingDeviceFilteredHistory(
            fieldName,
            type,
            id,
            startTS,
            endTS,
            filtrationFactor
        );
    }

    getTrackingDailyAggregates(
        type: string,
        id: string,
        startTS: number,
        numberOfDays: number,
        numberOfDaysQualifier: TimeIntervalType,
        continuousDates?: boolean
    ): QueryRef<{ dailyAggregatesByStartDate: Array<DailyAggregate> }> {
        return this.equipmentTrackingService.getTrackingDailyAggregates(
            type,
            id,
            startTS,
            numberOfDays,
            numberOfDaysQualifier,
            continuousDates
        );
    }
}
