import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'sam-pdf-button',
  standalone: true,
  templateUrl: './pdf-button.component.html',
  styleUrls: ['./pdf-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfButtonComponent  {
  file = input<any>();
  public get fileExists (): boolean {
    return Object.keys(this.file()).length > 0
  }
}
