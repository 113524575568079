import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EquipmentService } from '../../services/equipment.service';
import { MainImagePipeModule } from '../../shared/pipes/main-image.pipe';
import { EquipmentLocationComponent } from './components/equipment-location/equipment-location.component';
import { GeneralInformationComponent } from './components/general-information/general-information.component';
import { TechCheckComponent } from './components/tech-check/tech-check.component';
import { TelemetryComponent } from './components/telemetry/telemetry.component';
import { OperatingHoursComponent } from './components/operating-hours/operating-hours.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { BehaviorSubject } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { TrackingFacadeService } from '../../services/tracking.facade';
import { TrackingService } from '../../services/tracking.service';
import { EquipmentFacadeService } from '../../services/equipment.facade.service';
import { Equipment } from '../../shared/interfaces/equipment.interface';

@Component({
  standalone: true,
  providers: [EquipmentService,TrackingFacadeService, TrackingService, EquipmentFacadeService, EquipmentService],
  imports: [
    CommonModule,
    MainImagePipeModule,
    EquipmentLocationComponent,
    GeneralInformationComponent,
    TechCheckComponent,
    TelemetryComponent,
    OperatingHoursComponent,
    DocumentsComponent,
    MatProgressBarModule
  ],
  selector: 'sam-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentDetailComponent {
private readonly cdr = inject(ChangeDetectorRef);
private readonly route = inject(ActivatedRoute);
private readonly equipmentService = inject(EquipmentService);
public loading$  = new BehaviorSubject<boolean>(
  true
);

equipment!: Equipment

constructor(){
  this.getEquipment(this.route.snapshot.params['id'])
}

getEquipment (id: string) {
  this.equipmentService.getEquipmentDetails(id).pipe(
   
  )
  .subscribe(equipment => {
    console.log(equipment)
    this.equipment = equipment
    this.loading$.next(false);
    this.cdr.detectChanges()
  });
}

}
