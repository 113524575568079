import { Component, input, OnInit } from '@angular/core';
import { MainImagePipeModule } from '../../../../shared/pipes/main-image.pipe';
import { RouterModule } from '@angular/router';

@Component({
  standalone: true,
  imports: [MainImagePipeModule, RouterModule],
  selector: 'sam-equipment-card',
  templateUrl: './equipment-card.component.html',
  styleUrls: ['./equipment-card.component.scss']
})
export class EquipmentCardComponent implements OnInit {
  equipment = input<any>();

  constructor() { }

  ngOnInit() {
  }

}
