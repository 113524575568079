"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var MessageTypes = function () {
  function MessageTypes() {
    throw new Error('Static Class');
  }
  MessageTypes.GQL_CONNECTION_INIT = 'connection_init';
  MessageTypes.GQL_CONNECTION_ACK = 'connection_ack';
  MessageTypes.GQL_CONNECTION_ERROR = 'connection_error';
  MessageTypes.GQL_CONNECTION_KEEP_ALIVE = 'ka';
  MessageTypes.GQL_CONNECTION_TERMINATE = 'connection_terminate';
  MessageTypes.GQL_START = 'start';
  MessageTypes.GQL_DATA = 'data';
  MessageTypes.GQL_ERROR = 'error';
  MessageTypes.GQL_COMPLETE = 'complete';
  MessageTypes.GQL_STOP = 'stop';
  MessageTypes.SUBSCRIPTION_START = 'subscription_start';
  MessageTypes.SUBSCRIPTION_DATA = 'subscription_data';
  MessageTypes.SUBSCRIPTION_SUCCESS = 'subscription_success';
  MessageTypes.SUBSCRIPTION_FAIL = 'subscription_fail';
  MessageTypes.SUBSCRIPTION_END = 'subscription_end';
  MessageTypes.INIT = 'init';
  MessageTypes.INIT_SUCCESS = 'init_success';
  MessageTypes.INIT_FAIL = 'init_fail';
  MessageTypes.KEEP_ALIVE = 'keepalive';
  return MessageTypes;
}();
exports.default = MessageTypes;
