import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PdfButtonComponent } from '../../../../shared/components/pdf-button/pdf-button.component';

@Component({
  standalone: true,
  selector: 'sam-tech-check',
  imports: [TranslateModule, CommonModule, PdfButtonComponent],
  templateUrl: './tech-check.component.html',
  styleUrls: ['./tech-check.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TechCheckComponent {
  equipment = input<any>();
 public get isEmpty (): boolean {
  return !this.equipment()?.techCheckTypes?.length
 }

}
