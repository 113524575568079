import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule, CommonModule],
  selector: 'sam-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralInformationComponent implements OnInit {
  equipment = input<any>();

  constructor() { }

  ngOnInit() {
  }

}
