<mat-progress-bar 
  mode="indeterminate" 
  *ngIf="loading$ | async">
</mat-progress-bar>

<ng-container *ngIf="equipment">
  <div class="equipment-details">
    <img 
      class="equipment-details__image" 
      [src]="equipment?.equipmentMedia | mainImage" 
      alt="equipment" 
    />

    <div class="equipment-details__title">{{ equipment?.title }}</div>
    <div class="equipment-details__category">{{ equipment?.category?.name }}</div>
    <div class="equipment-details__invNumber">{{ equipment?.invNumber }}</div>

    <sam-equipment-location [equipment]="equipment"></sam-equipment-location>
    <sam-general-information [equipment]="equipment"></sam-general-information>
    <sam-tech-check [equipment]="equipment"></sam-tech-check>
    <sam-telemetry [equipment]="equipment"></sam-telemetry>
    <sam-operating-hours [equipment]="equipment"></sam-operating-hours>
    <sam-documents [equipment]="equipment"></sam-documents>

  </div>
</ng-container>
