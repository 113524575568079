import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService, MicroServicesEnum } from './api-gateway.service';
import { Equipment } from '../shared/interfaces/equipment.interface';
import { EquipmentCategory } from '../shared/interfaces/equipment-category.interface';
import { HttpHeaders } from '@angular/common/http';
import { ReportTsFilterConfig } from '../shared/interfaces/reports-config.interface';

@Injectable()
export class EquipmentService {
    constructor(private readonly apiService: ApiGatewayService) {}

    public getEquipments<T, N>(filter: N): Observable<T> {

        return this.apiService.get<T>(
            'equipments',
            filter,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public getEquipmentDetails(equipmentId: string): Observable<any> {
        return this.apiService.get<any>(
            `equipments/${equipmentId}`,
            { withMedia: true },
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public getEquipmentById(equipmentId: string): Observable<Equipment> {
        return this.apiService.get<Equipment>(
            `equipments/${equipmentId}`,
            { withMedia: true },
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public deleteEquipment<T>(equipmentId: string): Observable<T> {
        return this.apiService.delete<T>(
            `equipments/${equipmentId}`,
            null,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public getEquipmentCategories(
        data = null
    ): Observable<Array<EquipmentCategory>> {
        return this.apiService.get(
            `categories`,
            data,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public getTechCheckTypes<T>(): Observable<T> {
        return this.apiService.get(
            `bff/equipments/tech-checks`,
            null,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public makeEquipmentInactive(equipment: Equipment) {
        const body = {
            inactive: {
                startDate: equipment?.inactive?.startDate,
                endDate: equipment?.inactive?.endDate,
                reason: equipment?.inactive?.reason,
                description: equipment?.inactive?.description,
            },
        };

        return this.apiService.put(
            `equipments/${equipment.id}`,
            body,
            null,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public makeEquipmentActive(equipment: Equipment) {
        return this.apiService.put(
            `equipments/${equipment.id}`,
            { inactive: null },
            null,
            MicroServicesEnum.equipmentsApiSubPath
        );
    }

    public uploadFile<T>(file: File): Observable<T> {
        let formData: FormData = new FormData();
        formData.append('files', file, file.name.toLowerCase());
        let headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        return this.apiService.upload(
            `media`,
            formData,
            null,
            MicroServicesEnum.mediaApiSubPath
        );
    }

    public getBeaconVendorConfig<T>(): Observable<T> {
        return this.apiService.get<T>(
            'web/data/v1/providers',
            null,
            MicroServicesEnum.telematicsApiSubPath
        );
    }

    createReport(reportFiltercConfig: ReportTsFilterConfig) {
        return this.apiService.post(
            'api/v1/common/task/report',
            reportFiltercConfig,
            null,
            MicroServicesEnum.webBFF
        );
    }

    public getLocation<T>(ids: string[]): Observable<T> {
        return this.apiService.post<T>(
            'equipment/multi-info?alsoNoCheckinout=true',
            ids,
            { addresses: true },
            MicroServicesEnum.eqposSubPath
        );
    }

    public getLocationReverseGeoCode<T>(coordinates: {
        lat: string;
        lng: string;
    }): Observable<T> {
        return this.apiService.get<T>(
            'web/v1/reverse-geocode',
            { longitude: coordinates.lng, latitude: coordinates.lat },
            MicroServicesEnum.positioningApiSubPath
        );
    }
}
