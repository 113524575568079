<div class="documents"
[ngClass]="{'empty': !isEmpty}"
>

  <div class="documents__wrapper">
    <div class="documents__header">
      <img class="documents__icon" src="../../../../../assets/images/5.svg" alt="">
      <span class="documents__title">{{'documents' | translate}}</span>
    </div>
   
    <span class="divider"></span>
    <span class="documents__title">{{'technical_description' | translate}}</span>

    @if (documents?.length) {
      <div *ngFor="let document of documents | keyvalue">
        <sam-pdf-button [file]="document?.value"></sam-pdf-button>
    </div>
    } @else {
      <div class="not-indicated">{{'nothing_uploaded' | translate}}</div>
    }
  
    <span class="documents__title">{{'other_documents' | translate}}</span>
    
    @if (equipment()?.equipmentDocs?.length) {
      <div *ngFor="let document of equipment()?.equipmentDocs">
        <sam-pdf-button [file]="document"></sam-pdf-button>
    </div>
    }@else {
      <div class="not-indicated">{{'nothing_uploaded' | translate}}</div>
    }
  
  </div>
</div>

