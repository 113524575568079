// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageJson from '../../package.json';

export const environment = {
    serviceWorker: true,
    production: false,
    version: packageJson.version,
    configPath: 'config.json',
    federation: {
        manifest: '/assets/federation.manifest.dev.json',
    },
    imports: [
        // StoreDevtoolsModule.instrument({
        //     maxAge: 50,
        //     autoPause: true,
        //     connectInZone: true,
        // }),
    ],
};

