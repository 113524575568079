"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WS_TIMEOUT = exports.MIN_WS_TIMEOUT = void 0;
var MIN_WS_TIMEOUT = 1000;
exports.MIN_WS_TIMEOUT = MIN_WS_TIMEOUT;
var WS_TIMEOUT = 30000;
exports.WS_TIMEOUT = WS_TIMEOUT;
