import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Equipment, TechCheckType } from '../shared/interfaces/equipment.interface';
import { EquipmentService } from './equipment.service';

@Injectable()
export class EquipmentFacadeService {
    constructor(protected equipmentService: EquipmentService) {}

    public uploadFile<T>(file: File): Observable<T> {
        return this.equipmentService.uploadFile(file);
    }

    public getEquipments<T, N>(filter: N): Observable<T> {
        return this.equipmentService.getEquipments<T, N>(filter);
    }

    public getEquipmentById(equipmentId: string): Observable<Equipment> {
        return this.equipmentService.getEquipmentById(equipmentId);
    }

    public getBeaconVendorConfig<T>(): Observable<T> {
        return this.equipmentService.getBeaconVendorConfig<T>();
    }

    public getTechCheckTypes(): Observable<{ checks: TechCheckType[] }> {
        return this.equipmentService.getTechCheckTypes();
    }
}
