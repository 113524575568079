{
  "name": "sam-equipment-viewer",
  "version": "0.0.1",
  "scripts": {
    "ng": "ng",
    "start:dev": "ng serve --watch --configuration=development",
    "start:prod": "ng serve --watch --configuration=production",
    "build": "ng build",
    "build:prod": "ng build --configuration=production",
    "watch": "ng build --watch --configuration development",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.12",
    "@angular/cdk": "^19.1.1",
    "@angular/common": "^17.2.0",
    "@angular/compiler": "^17.2.0",
    "@angular/core": "^17.2.0",
    "@angular/forms": "^17.2.0",
    "@angular/material": "^19.1.1",
    "@angular/platform-browser": "^17.2.0",
    "@angular/platform-browser-dynamic": "^17.2.0",
    "@angular/pwa": "^19.1.4",
    "@angular/router": "^17.2.0",
    "@angular/service-worker": "^17.3.12",
    "@apollo/client": "^3.11.8",
    "@ngrx/effects": "^19.0.0",
    "@ngrx/store": "^19.0.0",
    "@ngx-translate/core": "^16.0.4",
    "@ngx-translate/http-loader": "^16.0.1",
    "@sharemactechhub/frontend-library": "17.3.32",
    "angular-notifier": "^14.0.0",
    "apollo-angular": "7.0.1",
    "graphql": "^16.6.0",
    "install": "^0.13.0",
    "lodash-es": "^4.17.11",
    "moment": "^2.30.1",
    "moment-timezone": "^0.5.46",
    "ng2-charts": "^3.0.11",
    "ngx-cookie-service": "^19.0.0",
    "ngx-mask": "13.0.1",
    "npm": "^11.0.0",
    "rxjs": "~7.8.0",
    "subscriptions-transport-ws": "^0.11.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.2.2",
    "@angular/cli": "^17.2.2",
    "@angular/compiler-cli": "^17.2.0",
    "@types/jasmine": "~5.1.0",
    "@types/lodash-es": "^4.17.12",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
