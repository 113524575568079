import {
    MemoizedSelector,
    createFeatureSelector,
    createSelector,
} from '@ngrx/store';
import { userFeatureKey, UserState } from './user.reducer';
import { User } from '../../shared/interfaces/user.interface';

const userState = createFeatureSelector<UserState>(userFeatureKey);

export const selectUserAuthStatus = () =>
    createSelector(userState, (state) => state.authStatus);

export const selectUserInfo = <T = Partial<User>>(
    fields?: string[]
): MemoizedSelector<object, T | Partial<User> | Record<string, never>> =>
    createSelector(userState, (state) => {
        if (!state?.user?.id) {
            return {};
        }
        const user = state.user;  // Safe assertion since we checked id exists
        if (fields?.length) {
            return fields.reduce((acc, item) => ({
                ...acc,
                [item]: user[item as keyof User]
            }), {} as T);
        }
        return user as T;
    });

export const selectActiveUserId = () =>
    createSelector(userState, (state) => {
        if (!state?.user?.id) {
            return;
        }
        return state?.user?.id;
    });

export const selectUsersOrganizationId = (): MemoizedSelector<
    object,
    string | undefined
> =>
    createSelector(userState, (state) => {
        return state?.user?.organizationId;
    });

export const selectOrganizationTimezone = (): MemoizedSelector<
    object,
    string | undefined
> => {
    return createSelector(userState, (state) => state.user?.orgTimezone);
};
