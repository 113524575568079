import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs';
import { CoreConfigService } from '@sharemactechhub/frontend-library/config-loader';
import { selectUserAuthStatus } from '../../store/user/user.selectors';
import { AuthStatus } from '../../store/user/user.reducer';

export const canActivateFn: CanActivateFn = () => {
    const store = inject(Store);
    const coreConfig = inject(CoreConfigService);
    return store.select(selectUserAuthStatus()).pipe(
        map((status) => status === AuthStatus.Authorized),
        tap((isAuth) => {
            if (!isAuth) {
                window.location.href = coreConfig.select((r) => r.redirects.login.url) +
                    encodeURIComponent(window.location.href);
            }
        })
    );
};
