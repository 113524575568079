export function removeEmpty<T = unknown>(obj: T): T {
    if (!obj) {
        return obj;
    }
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => {
            if (Array.isArray(v)) return v.length > 0;
            if (typeof v === 'object' && !(v instanceof Date))
                return Object.keys(removeEmpty(v) || {}).length > 0;
            return (
                typeof v === 'number' || typeof v === 'boolean' || Boolean(v)
            );
        })
    ) as T;
}
