import { Pipe, PipeTransform, OnDestroy, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';

interface CustomDateInput {
    value: any;
    format?: string;
    timezone?: string;
}

interface CustomDateData {
    input: CustomDateInput;
    subject: BehaviorSubject<string>;
}

@Pipe({
    name: 'customDate',
})
export class CustomDatePipe implements PipeTransform, OnDestroy {
    private readonly formats = {
        en: 'd MMMM yyyy',
        de: 'd. MMMM yyyy',
    };
    private readonly langSub: Subscription;
    private readonly inputData: CustomDateData[] = [];

    constructor(
        private readonly datepipe: DatePipe,
        private readonly translate: TranslateService
    ) {
        this.langSub = this.translate.onLangChange.subscribe(() =>
            this.inputData.forEach((d) => d.subject.next(this.format(d.input)))
        );
    }

    ngOnDestroy() {
        this.langSub.unsubscribe();
        this.inputData.forEach((d) => d.subject.complete());
        this.inputData.length = 0;
    }

    transform(
        value: any,
        format?: string,
        timezone?: string
    ): Observable<string> {
        const input: CustomDateInput = {
            value: value,
            format: format,
            timezone: timezone,
        };
        const result = this.format(input);
        const subject = new BehaviorSubject<string>(result);
        const data: CustomDateData = {
            input: input,
            subject: subject,
        };
        this.inputData.push(data);
        return subject;
    }

    private format({
        value,
        format,
        timezone,
    }: {
        value: any;
        format?: string;
        timezone?: string;
    }): string {
        format = format || this.formats[this.translate.currentLang as keyof typeof this.formats];
        return this.datepipe.transform(
            value,
            format || '',
            timezone,
            this.translate.currentLang
        ) || '';
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [CustomDatePipe],
    exports: [CustomDatePipe],
    providers: [DatePipe]
})
export class CustomDatePipeModule {}
