import { createAction, props } from '@ngrx/store';
import { User } from '../../shared/interfaces/user.interface';

export const syncAuthStatus = createAction(
    '[USER] Synchronize Auth Status',
    props<{ hasIdToken: boolean }>()
);

export const getUserInfo = createAction('[USER] Get User Info');

export const getUserInfoSuccess = createAction(
    '[USER] Get User Info Success',
    props<{ user: User }>()
);

export const logoutUser = createAction('[USER] Logout');
