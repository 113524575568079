import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { APP_INITIALIZER, LOCALE_ID, NgModule, isDevMode } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { UserEffects } from "./store/user/user.effects";
import { TranslateService, TranslateStore } from "@ngx-translate/core";
import { UserService } from "./services/user.service";
import { Store, StoreModule } from "@ngrx/store";
import { AppComponent } from "./app.component";
import { CoreConfigModule, CoreConfigService, selectCoreConfig, TranslateLoaderModule } from "@sharemactechhub/frontend-library/config-loader";
import { filter, first, switchMap, tap } from "rxjs";
import { getUserInfoSuccess } from "./store/user/user.actions";
import { StorageMigration } from "./storage-migration";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { RoutingModule } from "./app.routes";
import { ApiGatewayService } from "./services/api-gateway.service";
import { SiteConfigurationSchema } from "./shared/interfaces/config.interface";
import { environment } from "../environments/environment";
import { RoleGuard } from "./shared/guards";
import { AUT_INTERCEPTOR_PROVIDER } from "./shared/interceptors";
import { NotifierService } from "angular-notifier";
import { ServiceWorkerModule } from '@angular/service-worker';
import { EquipmentListComponent } from "./pages/equipment-list/equipment-list.component";
import { EquipmentDetailComponent } from "./pages/equipment-detail/equipment-detail.component";
import { NgxMaskModule } from 'ngx-mask';
import { EquipmentTrackingService } from "./services/equipment-tracking.service";
import { HeaderComponent } from "./shared/components/header/header.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { REDUCERS } from "./store";

export function MigrationFactory(cookieService: CookieService) {
    return (): boolean => {
        new StorageMigration(cookieService).cookieDataMigration();
        return true;
    };
}

registerLocaleData(localeDe);
registerLocaleData(localeEn);

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        HttpClientJsonpModule,
        RoutingModule,
        NgxMaskModule.forRoot(),
        StoreModule.forRoot(REDUCERS, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
                strictStateSerializability: false,
                strictActionSerializability: false,
            }
        }),
        EffectsModule.forRoot([
            UserEffects,
        ]),
        
        CoreConfigModule.forRoot<SiteConfigurationSchema>({
            preload: true,
            path: `../../../configs/${environment.configPath}`,
        }),
        TranslateLoaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
        EquipmentListComponent,
        EquipmentDetailComponent,
        HeaderComponent,
    ],
    providers: [
    EquipmentTrackingService,
        TranslateService,
        TranslateStore,
        ApiGatewayService,
        UserService,
        CoreConfigService,
        RoleGuard,
        AUT_INTERCEPTOR_PROVIDER,
        NotifierService,
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: (translateService: TranslateService) => {
                return translateService.currentLang;
            },
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initApplication,
            deps: [Store, UserService],
            multi: true,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: MigrationFactory,
            deps: [CookieService],
            multi: true,
        },
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useFactory: (userService: UserService) => ({
                timezone: userService.getTimeZoneOffset(),
            }),
            deps: [UserService],
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initApplication(
    store: Store,
    userService: UserService
): Function {
    return () =>
        store.select(selectCoreConfig).pipe(
            filter((res) => Boolean(res.config)),
            switchMap(() => userService.getUserInfo()),
            tap({
                next: (user) => store.dispatch(getUserInfoSuccess({ user })),
            }),
            first()
        );
}
