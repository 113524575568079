import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, Input, input, OnInit, ViewChild } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EquipmentCardComponent } from '../equipment-card/equipment-card.component';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, Subject, switchMap, tap } from 'rxjs';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { EquipmentService } from '../../../../services/equipment.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Equipment } from '../../../../shared/interfaces/equipment.interface';
import { BaseTableComponent } from '../../../../shared/classes/base-table.class';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { removeEmpty } from '../../../../shared/utils/object-utility';
import { SearchParameters } from '../../../../shared/interfaces/search-parameters.interface';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    EquipmentCardComponent,
    MatProgressBarModule,
    TranslateModule
  ],
  providers: [EquipmentService],
  selector: 'sam-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseTableComponent<Equipment> implements OnInit, AfterViewInit {

  private cursor!: { locationCursor?: string } | null;


  private filter!: SearchParameters;

  public loading$ = new BehaviorSubject<boolean>(
    true
  );
  public equipments: any[] = [];

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  override viewport?: CdkVirtualScrollViewport;


  @Input() searchParams$!: Subject<SearchParameters>;


  private readonly destroyRef = inject(DestroyRef);
  private readonly equipmentService = inject(EquipmentService);
  private readonly cdr = inject(ChangeDetectorRef);

  constructor() {
    super();
  }

  override loadDataUntil(): boolean {
    return (
      Boolean(this.cursor?.locationCursor) ||
      this.dataSource.length < this.totalCount
    );
  }
  override ngOnInit(): void {
    super.ngOnInit();
    this.onFilterChange()
    this.getEquipments();

  }

  ngAfterViewInit(): void {
  }

  trackByEquipmentId(index: number, equipment: any): string {
    return equipment.id;
  }

  private getEquipments(): void {
    this.loading = true;
    const params = {
      isBmti: 0,
      withTechCheckInfo: 1,
      showChildren: 1,
      withMedia: true,
      withKologne: true,
      language: "en",
      withResponsiblePerson: true,
    };
    this.pagingChange$
      .pipe(
        filter(() => !!this.filter),
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.loading = true),
        debounceTime(100),
        map((paging) => [this.filter.search, paging] as [string, Paging]),
        switchMap(
          ([filter, paging]: [string, Paging]) => {
            return this.equipmentService
              .getEquipments<any, any>({
                search: filter,
                page: paging.page,
                limit: paging.limit,
                ...params
              })
              .pipe(
                map((resp) => ({
                  resp: { ...resp },
                  filter: { filter, reset: this.page === 1 },
                })),
              );
          }
        ),
        tap(({ resp }: any) => {
          this.cursor = removeEmpty(resp.cursor);
        }),
        tap((response) => (this.totalCount = response.resp.total))
      )
      .subscribe({
        next: ({ resp, filter }) => {
          if (filter.reset) {
            this.dataSource.allData = resp.content;
          } else {
            this.dataSource.add(resp.content);
          }
          this.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  private cursorExistence(filter: any): void {
    if (
      this.filter?.params?.locationDistanceInKm !==
      filter?.params?.locationDistanceInKm
    ) {
      this.cursor = null;
    }
  }
  onFilterChange() {
    this.searchParams$
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            JSON.stringify(prev) === JSON.stringify(curr)
        ),
        filter(Boolean),
        tap(this.cursorExistence)

      )
      .subscribe({
        next: (filter) => {
          this.filter = filter;
          this.resetPaging();
          this.viewport?.scrollTo({
            top: 0,
          });
        },
      });
  }
}

export interface Paging {
  page: number,
  limit: number
}
