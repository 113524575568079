import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PermissionEnum } from '../enums/permission.enum';
import { selectUserInfo } from '../../store/user/user.selectors';
import { User } from '../interfaces/user.interface';

@Injectable()
export class RoleGuard {
    constructor(
        private readonly store: Store,
        private readonly router: Router
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        const permissions = route.data['permissions'] as PermissionEnum[];
        const redirectTo = route.data['redirectTo'] as string;
    
        return new Observable<boolean>((observer) => {
            this.store
                .pipe(select(selectUserInfo(['permissions'])))
                .subscribe((me: User) => {
                    if (!me || !me.permissions) {
                        observer.next(false);  // Return false if user info or permissions are missing
                        observer.complete();
                        if (redirectTo) {
                            this.router.navigate([redirectTo]);
                        }
                        return;
                    }
    
                    const accessPermissions = permissions.every(
                        (i) => !!me.permissions?.[i]
                    );
    
                    if (!accessPermissions && redirectTo) {
                        this.router.navigate([redirectTo]);
                        observer.next(false);
                    } else {
                        observer.next(accessPermissions);
                    }
    
                    observer.complete();
                });
        });
    }
    
}
