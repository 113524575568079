import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from '@sharemactechhub/frontend-library/common';
import { FormFieldModule } from '@sharemactechhub/frontend-library/form-field';
import { InputModule as ShlInputModule } from '@sharemactechhub/frontend-library/input';
import { ListComponent } from './components/list/list.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, debounceTime, Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { EquipmentFilter } from '../../shared/interfaces/equipment-filter.interface';
import { FilterState } from '../../shared/interfaces/filter.interface';

@Component({
  standalone: true,
  selector: 'sam-equipment-list',
  imports: [
    CommonModule,
    TranslateModule,
    ShlInputModule,
    FormFieldModule,
    DirectivesModule,
    ListComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EquipmentListComponent implements OnInit {
  searchControl = new FormControl<string>('');
  searchParams$: BehaviorSubject<any> = new BehaviorSubject(this.getFilterState());

  constructor() { }

  ngOnInit() {
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe(filter => {
      if (filter) {
        this.searchParams$.next({ params: this.getFilterState(), search: filter })
      } else {
        this.searchParams$.next({ params: this.getFilterState() })
      }
    })
  }
  private readonly filter: Partial<EquipmentFilter> = {
    withTechCheckInfo: 1,
  };

   getFilterState(): FilterState & {
    withMedia: boolean;
    withKologne: boolean;
  } {
    return {
      isBmti: 0,
      ...this.filter,
      showChildren: 1,
      withMedia: true,
      withKologne: true,
      language: 'de',
    };
  }

}
