import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, input, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TrackingFacadeService } from '../../../../services/tracking.facade';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApolloModule } from 'apollo-angular';
import { TrackingService } from '../../../../services/tracking.service';
import { EquipmentFacadeService } from '../../../../services/equipment.facade.service';
import { CommonModule } from '@angular/common';
import { TrackingResult } from '../../../../shared/interfaces';

@Component({
  standalone: true,
  selector: 'sam-telemetry',
  imports: [TranslateModule, ApolloModule, CommonModule],
  providers: [
    TrackingFacadeService,
    TrackingService,
    EquipmentFacadeService
  ],
  templateUrl: './telemetry.component.html',
  styleUrls: ['./telemetry.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TelemetryComponent implements OnInit {
  equipment = input<any>();
  newEquipment!: any

  private readonly equipmentTrackingFacade = inject(TrackingFacadeService);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly destroyRef = inject(DestroyRef);
  public trackingData!: TrackingResult | null


  constructor() { }

  ngOnInit() {
    this.subscribeTrackingData()
    this.loadTrackingData();
  }


  private loadTrackingData(): void {
    this.newEquipment = this.convertEquipmentToOldFormat(this.equipment());
    this.equipmentTrackingFacade.loadTrackingData(
      this.newEquipment.field_beacon_vendor,
      this.newEquipment.field_beacon_id
    );
    this.cdr.detectChanges()
  }
  private convertEquipmentToOldFormat(newEquipment: any): any {
    return {
      title: newEquipment?.title,
      field_beacon_id: newEquipment?.beaconId,
      field_beacon_vendor: newEquipment?.beaconVendor,
      field_address: null,
      id: newEquipment?.id,
      field_category: newEquipment?.category,
      field_organisation: newEquipment?.organization
        ? {
          id: newEquipment?.organization.id,
          title: newEquipment?.organization.name,
        }
        : {},
      parentId: newEquipment?.parentId,
      field_parent: newEquipment?.parent,
      field_unic_equipment_id: newEquipment?.unique_equipment_id,
      field_initial_working_hours: newEquipment?.workingHours,
      field_daily_price_hist: JSON.stringify(
        newEquipment?.dailyPrice || {}
      ),
      field_rfid: newEquipment?.RFID,
      field_is_inactive: Boolean(newEquipment?.inactive),
      field_internal_inventory_number: newEquipment?.invNumber,
      position: newEquipment?.position ?? null,
      device: newEquipment?.device ?? null,
    } as any;
  }
  private subscribeTrackingData(): void {
    this.equipmentTrackingFacade.currentTrackingData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((trackingResult) => {
        if (trackingResult) {
          this.trackingData = { ...trackingResult };
        } else {
          this.trackingData = null;
        }
        this.cdr.detectChanges()
      });
  }

}
