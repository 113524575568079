import { HttpHeaders } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { WebSocketLink } from '@apollo/client/link/ws';
import { CoreConfigService } from '@sharemactechhub/frontend-library/config-loader';
import { Apollo, QueryRef } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { getOperationAST } from 'graphql';
import { merge, omitBy } from 'lodash-es';
import moment from 'moment';
import momentZn from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteConfigurationSchema } from '../shared/interfaces/config.interface';
import { Equipment } from '../shared/interfaces/equipment.interface';
import { CURRENT_DEVICE_DATA, DAILY_AGGREGATES_BY_START_DATE, DEVICE_DATA_SUBSCRIPTION, QUERY_DEVICE_FILTERED_HISTORY, SIM_CARD_IMSI_INFORMATION, TRACKING_FIELD_HISTORY, TRACKING_FULL_HISTORY } from '../assets/query';
import { TimeIntervalType } from '../shared/enums/time-interval-type.enum';
import { DailyAggregate, IMSIInformation, IWorkingHours, TrackingDeviceHistory, TrackingResult } from '../shared/interfaces';

interface CurrentDeviceDataResponse {
    currentDeviceData: TrackingResult;
}

interface DeviceDataUpdatesResponse {
    deviceDataUpdates: TrackingResult['deviceData'];
}

interface FilteredHistoryResponse {
    deviceFilteredHistory: TrackingDeviceHistory;
}

@Injectable()
export class EquipmentTrackingService {
    public trackingData!: TrackingResult | undefined | null;
    private readonly currentTrackingData$ = new BehaviorSubject<TrackingResult | null>(
        null
    );
    private readonly workingHours$ = new BehaviorSubject<IWorkingHours[] | null>(null);
    private readonly trackingFullHistoryData$ =
        new BehaviorSubject<TrackingDeviceHistory | null>(null);

    private querySubscription!: Subscription;
    private subscriptionSubscription!: Subscription;
    private readonly workingHoursSubscription!: Subscription;
    private trackingFullHistorySubscription!: Subscription;
    public inited!: boolean;

    constructor(
        @Optional()
        @SkipSelf()
        equipmentTrackingService: EquipmentTrackingService,
        private readonly apollo: Apollo,
        private readonly httpLink: HttpLink,
        private readonly cookieService: CookieService,
        private readonly coreConfig: CoreConfigService<SiteConfigurationSchema>
    ) {
        if (!equipmentTrackingService) {
            this.createApollo();
        }
    }

    private createApollo(): void {
        const telematics = this.coreConfig.select((r) => r.telematics);
        const http = this.httpLink.create({
            uri: telematics.api,
            headers: new HttpHeaders({
                Authorization: `Bearer ${this.cookieService.get(
                    'AccessToken'
                )}`,
            }),
        });

        const ws = new WebSocketLink({
            uri: telematics.subscription,
            options: {
                reconnect: true,
                connectionParams: {
                    authorization: `${this.cookieService.get('AccessToken')}`,
                },
            },
        });

        this.apollo.create(
            {
                link: ApolloLink.split(
                    (operation) => {
                        const operationAST = getOperationAST(
                            operation.query,
                            operation.operationName
                        );
                        return (
                            !!operationAST &&
                            operationAST.operation === 'subscription'
                        );
                    },
                    ws,
                    http
                ),
                cache: new InMemoryCache(),
                defaultOptions: {
                    watchQuery: {
                        fetchPolicy: 'no-cache',
                    },
                    query: {
                        fetchPolicy: 'no-cache',
                    },
                },
            },
            'tracking'
        );
    }

    public loadTrackingData(type: string, id: string): void {
        if (!type || !id) {
            this.currentTrackingData$.next(null);
            return;
        }
        this.querySubscription = this.apollo
            .use('tracking')
            .query<CurrentDeviceDataResponse>({
                query: CURRENT_DEVICE_DATA,
                variables: {
                    type: type,
                    id: id,
                },
                fetchPolicy: 'no-cache',
            })
            .subscribe(
                (res) => {
                    if (res.data?.currentDeviceData) {
                        this.currentTrackingData$.next(
                            res.data.currentDeviceData
                        );
                        this.trackingData = res.data.currentDeviceData;
                    } else {
                        this.currentTrackingData$.next(null);
                        this.trackingData = undefined;
                    }
                },
                () => {
                    this.currentTrackingData$.next(null);
                    this.trackingData = undefined;
                }
            );

        this.subscriptionSubscription = this.apollo
            .use('tracking')
            .subscribe<DeviceDataUpdatesResponse>({
                query: DEVICE_DATA_SUBSCRIPTION,
                variables: {
                    type: type,
                    id: id,
                    authorization: `${this.cookieService.get('AccessToken')}`,
                },
            })
            .subscribe((res) => {
                if (res.data?.deviceDataUpdates) {
                    const currentTrackingData =
                        this.currentTrackingData$.getValue();
                    if (currentTrackingData) {
                        // we need to remove null values from response since it overrides existing values
                        const filteredResponse = omitBy(
                            res.data.deviceDataUpdates,
                            (v: any) => v === null
                        );

                        currentTrackingData.deviceData = merge(
                            currentTrackingData.deviceData,
                            filteredResponse
                        );
                        for (const key in currentTrackingData.fieldTS) {
                            if (
                                currentTrackingData.fieldTS[key as keyof typeof currentTrackingData.fieldTS] &&
                                !key.includes('_')
                            ) {
                                currentTrackingData.fieldTS[key as keyof typeof currentTrackingData.fieldTS] =
                                    Date.now().toString();
                            }
                        }
                    }
                    this.currentTrackingData$.next(currentTrackingData);
                    this.trackingData = currentTrackingData;
                }
            });
    }

    public clearTrackingData(): void {
        if (this.querySubscription) {
            this.querySubscription.unsubscribe();
        }
        if (this.subscriptionSubscription) {
            this.subscriptionSubscription.unsubscribe();
        }
        if (this.workingHoursSubscription) {
            this.workingHoursSubscription.unsubscribe();
        }
        if (this.trackingFullHistorySubscription) {
            this.trackingFullHistorySubscription.unsubscribe();
        }

        this.currentTrackingData$.next(null);
        this.trackingFullHistoryData$.next(null);

        this.trackingData = undefined;
    }

    public get currentTrackingData(): Observable<TrackingResult | null> {
        return this.currentTrackingData$.asObservable();
    }

    public get trackingFullHistoryData(): Observable<TrackingDeviceHistory | null> {
        return this.trackingFullHistoryData$.asObservable();
    }

    public get workingHoursData(): Observable<IWorkingHours[] | null> {
        return this.workingHours$.asObservable();
    }

    /**
     * @description gets telematics data history with provided node fields equipment.field_beacon_id
     * @param $type company identifier, something like "Sharemag-G2" (equipment.field_beacon_vendor), not null
     * @param $id sensor identifier, something like "SM-TEL-001" (equipment.field_beacon_id), not null
     * @param $startTS filter start date, not null
     * @param $endTS filter end date, not null
     * @param $first behaves like count (total), must be used with @param $after, if $after is not provided, then consideres as first page
     * @param $last behaves like count (total), must be used with @param $before, if $before is not provided, then consideres as last page
     * @param $before is cursor, optimized page identifier; must be used with @param $last
     * @param $after is cursor, optimized page identifier; must be used with @param $first
     * @returns GraphQL response of type TrackingDeviceHistory
     */
    public loadTrackingFullHistory(
        type: string,
        id: string,
        startTS: string,
        endTS: string,
        first?: number,
        after?: string
    ): void {
        this.trackingFullHistorySubscription = this.apollo
            .use('tracking')
            .subscribe({
                query: TRACKING_FULL_HISTORY,
                variables: {
                    type: type,
                    id: id,
                    startTS: moment(+startTS)
                        .startOf('day')
                        .format('x')
                        .toString(),
                    endTS: moment(+endTS).endOf('day').format('x').toString(),
                    first: first,
                    after: after,
                },
            })
            .pipe(map((x: any) => (x.data ? x.data.deviceHistory : null)))
            .subscribe((deviceHistory: TrackingDeviceHistory) => {
                this.trackingFullHistoryData$.next(deviceHistory);
            });
    }

    public getTrackingFieldHistory(
        fieldName: string,
        type: string,
        id: string,
        startTS: number,
        endTS: number,
        first?: number,
        after?: string
    ): QueryRef<{ deviceHistory: TrackingDeviceHistory }> {
        return this.apollo
            .use('tracking')
            .watchQuery<{ deviceHistory: TrackingDeviceHistory }>({
                query: TRACKING_FIELD_HISTORY(fieldName),
                variables: {
                    type: type,
                    id: id,
                    startTS: moment(+startTS)
                        .startOf('day')
                        .format('x')
                        .toString(),
                    endTS: moment(+endTS).endOf('day').format('x').toString(),
                    first: first,
                    after: after,
                },
            });
    }

    public getTrackingDailyAggregates(
        type: string,
        id: string,
        startTS: number,
        numberOfDays: number,
        numberOfDaysQualifier: TimeIntervalType,
        continuousDates?: boolean
    ): QueryRef<{ dailyAggregatesByStartDate: Array<DailyAggregate> }> {
        return this.apollo
            .use('tracking')
            .watchQuery<{ dailyAggregatesByStartDate: Array<DailyAggregate> }>({
                query: DAILY_AGGREGATES_BY_START_DATE,
                variables: {
                    type: type,
                    id: id,
                    startTS: momentZn(+startTS)
                        .tz('Europe/Berlin')
                        .startOf('day')
                        .format('x')
                        .toString(),
                    numberOfDays: numberOfDays,
                    numberOfDaysQualifier: numberOfDaysQualifier,
                    continuousDates: continuousDates,
                },
            });
    }

    public getTrackingDeviceFilteredHistory(
        fieldName: string,
        type: string,
        id: string,
        startTS: number,
        endTS: number,
        filtrationFactor: number
    ): QueryRef<FilteredHistoryResponse> {
        return this.apollo.use('tracking').watchQuery<FilteredHistoryResponse>({
            query: QUERY_DEVICE_FILTERED_HISTORY(fieldName),
            variables: {
                type,
                id,
                startTS: moment(+startTS).startOf('day').format('x').toString(),
                endTS: moment(+endTS).endOf('day').format('x').toString(),
                filtrationFactor,
            },
        });
    }

    public getSimCardIMSIInformation(
        imsi: string
    ): QueryRef<{ IMSIInformation: IMSIInformation }> {
        return this.apollo
            .use('tracking')
            .watchQuery<{ IMSIInformation: IMSIInformation }>({
                query: SIM_CARD_IMSI_INFORMATION,
                variables: {
                    imsi: imsi,
                },
            });
    }

    //#region equipment
    private readonly _equipment$: BehaviorSubject<Equipment | null> =
        new BehaviorSubject<Equipment | null>(null);

    public getEquipment$(): Observable<Equipment | null> {
        return this._equipment$.asObservable();
    }

    public setEquipment(equipment: Equipment): void {
        this._equipment$.next(equipment);
    }

    //#endregion
}
