export enum MicroServicesEnum {
    projApiSubPath = 'proj-api',
    userApiSubPath = 'user-api',
    equipmentsApiSubPath = 'eq-api',
    orgApiSubPath = 'org-api',
    webApi = 'web-api',
    adminSubPath = 'admin',
    mediaApiSubPath = 'media-api',
    eqposSubPath = 'eqpos-api',
    hrPlannerSubPath = 'hr-planner',
    kologne = 'kologne',
    lectura = 'lectura-api',
    transportations = 'transportations',
    telematicsApiSubPath = 'telematics-api',
    webBFF = 'web-bff',
    workingHours = 'working-hours-api',
    moveAwayBFFApiSubPath = 'move-away/bff',
    moveAwayApiSubPath = 'move-away',
    chargingApiSubPath = 'charging-api',
    positioningApiSubPath = 'positioning-api',
}