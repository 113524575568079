<div class="tech-check"
[ngClass]="{'empty': isEmpty}"
>
  <div class="tech-check__wrapper">
    <div class="tech-check__header">
      <img  class="tech-check__icon" src="../../../../../assets/images/2.svg" alt="">
      <span class="tech-check__title">{{'tech_check' | translate}}</span>
    </div>
   
    <span class="divider"></span>

    @if (isEmpty) {
      <div class="not-indicated">
        {{'not_done_before' | translate}}
      </div>
         
    } @else {
 <div class="tech-check__item" [ngClass]="{'not-indicated': !equipment()?.techCheckTypes?.length}">
      <span class="tech-check__item-title"> {{'tech_check_widget_info_label'|translate}}</span>
      
      @if (equipment()?.techCheckTypes?.length) {
        {{equipment()?.techCheckTypes[equipment()?.techCheckTypes?.length - 1]?.title}}      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

    <div class="tech-check__item" [ngClass]="{'not-indicated': !equipment()?.techCheckTypes?.length}">
      <span class="tech-check__item-title"> {{'tech_check_widget_last_check_label'|translate}}</span>
      @if (equipment()?.techCheckTypes?.length) {
        {{equipment()?.techCheckTypes[equipment()?.techCheckTypes?.length - 1]?.previousCheckDate | date : 'dd.MM.y'}}      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

    <div class="tech-check__item" [ngClass]="{'not-indicated': !equipment()?.techCheckTypes?.length}">
      <span class="tech-check__item-title"> {{'tech_check_widget_next_check_label'|translate}}</span>
      @if (equipment()?.techCheckTypes?.length) {
        {{equipment()?.techCheckTypes[equipment()?.techCheckTypes?.length - 1]?.nextCheckDate | date : 'dd.MM.y'}}      }@else {
        {{'not_indicated' | translate}}
      }
    </div>

    @if (equipment()?.techCheckTypes[equipment()?.techCheckTypes.length - 1]?.media) {
      <sam-pdf-button [file]="equipment()?.techCheckTypes[equipment()?.techCheckTypes?.length - 1]?.media"></sam-pdf-button>
    }

    }

   
  </div>

</div>