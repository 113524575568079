@if(fileExists) {
<a class="file-button" target="_blank" [href]="file().files[0].path">

  <div class="file-button__content">
    <div class="file-button__pdf"  >
      <img class="file-button__icon" src="../../../../assets/images/pdf.svg" alt="pdf">
      <a  class="file-button__link">
        {{file().name}}
      </a>
    </div>
    <img class="file-button__icon" src="../../../../assets/images/arrow.svg" alt="pdf">

  </div>
</a>
}