import { ChangeDetectionStrategy, Component, inject, OnInit, ViewEncapsulation } from "@angular/core";
import { NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { slideInAnimation } from "../assets/animations/router-navigation.animation";
import { filter, map, Observable } from "rxjs";

@Component({
  selector: 'sam-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [slideInAnimation],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  private readonly router = inject(Router);

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
  public isListingPage$: Observable<boolean>;
  
  ngOnInit(): void {}

  constructor() {
    this.isListingPage$ = this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects.includes('/list'))
    );
  }
}
