import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService, MicroServicesEnum } from './api-gateway.service';

@Injectable()
export class TrackingService {
    constructor(private readonly apiService: ApiGatewayService) {}

    public getEquipmentMultiInfo<T>(
        equipmentIds: Array<string>
    ): Observable<T> {
        return this.apiService.post<T>(
            'equipment/multi-info?alsoNoCheckinout=true',
            equipmentIds,
            { addresses: true },
            MicroServicesEnum.eqposSubPath
        );
    }

    public getWarehouseDetails<T>(id: string): Observable<T> {
        return this.apiService.get(
            `warehouses/${id}`,
            { isMapV2: true },
            MicroServicesEnum.projApiSubPath
        );
    }

    public getProjectDetails<Project>(
        projectId: string
    ): Observable<{ project: Project; status: string }> {
        return this.apiService.get<{ project: Project; status: string }>(
            `projects/${projectId}`,
            { isMapV2: true },
            MicroServicesEnum.projApiSubPath
        );
    }
}
