<div class="operating-hours">
  <div class="operating-hours__wrapper">
    <div class="operating-hours__header">
      <div class="operating-hours__title-wrapper">
        <img src="../../../../../assets/images/4.svg" alt="">
        <span class="operating-hours__title">{{'working-time' | translate}}</span>
      </div>

      <div class="operating-hours__toggle">
        <button class="work-hours__button work-hours__button-left" (click)="slide(false)">
          <img src="../../../../../assets/images/arrow-left-hours.svg" alt="left">
        </button>
        <button class="work-hours__button work-hours__button-right" (click)="slide(true)">
          <img src="../../../../../assets/images/arrow-right-hours.svg" alt="right">
        </button>
      </div>

    </div>

    <span class="divider"></span>

    @if (barChartData.length) {
    <div class="work-hours" *ngIf="barChartData.length">

      <div class="work-hours__canvas">
        <canvas  
        baseChart
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [type]="barChartType"></canvas>
      </div>
    </div>
    }@else {
    <div class="operating-hours__placeholder">
      <img src="../../../../../assets/images/placeholder.svg" alt="">
      <div>{{'no_data_yet'|translate}}</div>
    </div>
    }

    <div class="operating-hours__info">
      <div class="operating-hours__info-item">
        <span class="operating-hours__info-working"></span>
        {{'working_hours' | translate}}
      </div>
      <div class="operating-hours__info-item">
        <span class="operating-hours__info-idle"></span>
        {{'idle_hours' | translate}}
      </div>
    </div>

  </div>

</div>