<div class="equipment-location">
  <div class="equipment-location__info">
   <p class="equipment-location__title"> {{'machine_general_label_last-known-loc' | translate}}</p>
   @if (equipment()?.device?.lastPositionTime) {
    <div class="equipment-location__date">{{equipment()?.device?.lastPositionTime | customDate : 'dd.MM.y HH:mm:ss' | async}}</div>
   }
  </div>

  <div class="equipment-location__address" [ngClass]="{'equipment-location__address-empty': !equipment()?.position?.lastKnownAddress}" (click)="openMapPin(equipment()?.position?.lastLatitude, equipment()?.position?.lastLongitude)">
    @if(equipment()?.position?.lastKnownAddress) {
    <img  src="../../../../../assets/images/address.svg" alt="address">
      {{equipment()?.position?.lastKnownAddress}}
    } @else {
      <img  src="../../../../../assets/images/empty-location.svg" alt="address">
      {{'unknown' | translate}}
    }
  </div>

  <shl-map [dataLoaded]="true" [config]="config" [equipments]="equipments"></shl-map>

</div>