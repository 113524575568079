import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, ViewEncapsulation } from '@angular/core';
import {  NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { UserService } from '../../../services/user.service';
import { StoreModule } from '@ngrx/store';

@Component({
  selector: 'sam-header',
  standalone: true,
  imports: [
    RouterModule, 
    CommonModule,
    StoreModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent  {

  private readonly router = inject(Router);
  private readonly userService = inject(UserService);

  public isDetailPage$: Observable<boolean>;
 
  constructor() {
    this.isDetailPage$ = this.router.events.pipe(
      filter((event: any): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.urlAfterRedirects.includes('/details'))
    );
  }

  logOut() {
    this.userService.logoOut()
  }

}
