import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import momentZn from 'moment-timezone';
import { CookieService } from 'ngx-cookie-service';
import { Observable,  map, tap } from 'rxjs';
import { ApiGatewayService, MicroServicesEnum } from './api-gateway.service';
import { User } from '../shared/interfaces/user.interface';
import { getDomain } from '../shared/utils/get-domaun.util';
import { selectUserInfo } from '../store/user/user.selectors';
import { logoutUser } from '../store/user/user.actions';

@Injectable()
export class UserService {
    private user!: User;
    constructor(
        private readonly cookieService: CookieService,
        private readonly apiService: ApiGatewayService,
        private readonly store: Store
    ) {}

   async logoOut() {
        this.store.dispatch(logoutUser());
    }

    logoutUser(): boolean {
        let domain = getDomain(window.location.hostname, 4);
        if (domain.startsWith('.mobile')) domain = domain.replace('.mobile', '')
        this.cookieService.delete('overlapingShown', '/', domain);
        this.cookieService.delete('IdToken', '/', domain);
        this.cookieService.delete('RefreshToken', '/', domain);
        this.cookieService.delete('AccessToken', '/', domain);
        this.cookieService.delete('showMessage');
        this.cookieService.delete('showInformationBanner');

        return !this.cookieService.check('IdToken');
    }

    getUserInfo(): Observable<User> {
        return this.apiService
            .get('me', null, MicroServicesEnum.userApiSubPath)
            .pipe(tap((val) => (this.user = val)));
    }

    getTimeZone(): string {
        return this.user.orgTimezone || 'UTC';
    }

    getTimeZoneOffset(): string {
        return momentZn().tz(this.user.orgTimezone || 'UTC').format('ZZ');
    }

    readonly userPermission$ = this.store
        .select(selectUserInfo(['permissions']))
        .pipe(map((user) => user?.permissions));
}
