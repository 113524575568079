<div class="telemetry">
  <div class="telemetry__wrapper">
    <div class="telemetry__header">
      <img src="../../../../../assets/images/3.svg" alt="">
      <span class="telemetry__title">{{'telemetry' | translate}}</span>
    </div>

    <span class="divider"></span>

      <div class="telemetry__item" [ngClass]="{'not-indicated': !newEquipment?.field_beacon_vendor}">
        <span class="telemetry__item-title"> {{'e_t_t-device'|translate}}</span>
        @if (newEquipment?.field_beacon_vendor) {
        {{newEquipment?.field_beacon_vendor}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>
  
  
      <div class="telemetry__item" [ngClass]="{'not-indicated': !newEquipment?.field_beacon_id}">
        <span class="telemetry__item-title"> {{'e_t_t-beacon_id'|translate}}</span>
        @if (newEquipment?.field_beacon_id) {
        {{newEquipment?.field_beacon_id}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>
  
  
      <div class="telemetry__item telemetry__item-long" [ngClass]="{'not-indicated': !equipment()?.device?.lastHeartbeatTime}">
        <span class="telemetry__item-title"> {{'machine_general_label_last-heartbeat'|translate}}</span>
        <div class="telemetry__item-value">
          @if (equipment()?.device?.lastHeartbeatTime) {
            {{equipment()?.device?.lastHeartbeatTime | date : 'dd.MM.y HH:mm'}} }@else {
            {{'not_indicated' | translate}}
            }
        </div>
      
      </div>
  
  
  
      <div class="telemetry__item telemetry__item-long" [ngClass]="{'not-indicated': !equipment()?.device?.lastPositionTime}">
        <span class="telemetry__item-title"> {{'machine_general_label_last_postition-update'|translate}}</span>
        <div class="telemetry__item-value">
          @if (equipment()?.device?.lastPositionTime) {
            {{equipment()?.device?.lastPositionTime  | date : 'dd.MM.y HH:mm'}} }@else {
            {{'not_indicated' | translate}}
            }
        </div>
     
      </div>
  
  
      <div class="telemetry__item" [ngClass]="{'not-indicated': !trackingData?.deviceData?.workingHours}">
        <span class="telemetry__item-title"> {{'e_t_s_i-working_time'|translate}}</span>
        @if (trackingData?.deviceData?.workingHours) {
        {{trackingData?.deviceData?.workingHours}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>
  
  
      <div class="telemetry__item" [ngClass]="{'not-indicated': !trackingData?.deviceData?.batteryCapacityPercent}">
        <span class="telemetry__item-title"> {{'batterie'|translate}}</span>
        @if (trackingData?.deviceData?.batteryCapacityPercent) {
        {{trackingData?.deviceData?.batteryCapacityPercent}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>
  
  
      <div class="telemetry__item" [ngClass]="{'not-indicated': !trackingData?.deviceData?.powerVoltage}">
        <span class="telemetry__item-title"> {{'Voltage'|translate}}</span>
        @if (trackingData?.deviceData?.powerVoltage) {
        {{trackingData?.deviceData?.powerVoltage}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>
  
  
      <div class="telemetry__item" [ngClass]="{'not-indicated': !trackingData?.deviceData?.odometerDistance}">
        <span class="telemetry__item-title"> {{'e_t_a_i-odo'|translate}}</span>
        @if (trackingData?.deviceData?.odometerDistance) {
        {{trackingData?.deviceData?.odometerDistance}} }@else {
        {{'not_indicated' | translate}}
        }
      </div>


  </div>

</div>